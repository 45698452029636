<template>
  <div class="app-body">
    <div class="a-flex-rsbc">
      <bread-crumb></bread-crumb>
      <div v-if="canEditBtn">
        <el-button type="primary" icon="el-icon-refresh" plain @click="handleChange">
          编辑项目
        </el-button>
      </div>
    </div>
    <div class="a-w-100 a-fs-14 a-p-24 a-br-top-4 a-bg-white">
      <project-detail-base :income-id="id"></project-detail-base>
    </div>
    <div class="projectDetail a-flex-1">
      <div class="projectDetail-top-tabs a-w-100">
        <el-tabs v-model="activeName" class="my-tab" @tab-click="handleClick">
          <el-tab-pane label="统计信息" name="tjxx">
            <project-detail-order-stat :income-id="id"></project-detail-order-stat>
          </el-tab-pane>
          <el-tab-pane label="分成比例" name="fcbl">
            <project-detail-rate :income-id="id"></project-detail-rate>
          </el-tab-pane>
          <el-tab-pane label="站点信息" name="zdxx" class="zdxx">
            <project-detail-station-list :income-id="id"></project-detail-station-list>
          </el-tab-pane>
          <el-tab-pane label="分成比例修改记录" name="czls" class="zdxx">
            <project-detail-history :income-id="id"></project-detail-history>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import util from "../../../src/utils/util";
import ProjectDetailBase from "./project-detail-base.vue";
import PorjectDetailHistory from "./project-detail-history.vue";
import ProjectDetailOrderStat from "./project-detail-order-stat.vue";
import ProjectDetailRate from "./project-detail-rate.vue";
import PorjectDetailStationList from "./project-detail-station-list.vue";

export default {
  name: "ProjectDetail",

  components: {
    "project-detail-rate": ProjectDetailRate,
    "project-detail-base": ProjectDetailBase,
    "project-detail-station-list": PorjectDetailStationList,
    "project-detail-history": PorjectDetailHistory,
    "project-detail-order-stat": ProjectDetailOrderStat,
  },

  data() {
    return {
      targetList: null,
      util: util,
      activeT: 1,
      startTime: "",
      endTime: "",
      id: null,
      detail: {},
      href: "",
      detailAmout: {},
      activeName: "tjxx",
      tjxxTime: 0,
      dfactiveT: 1,
      tjxxTimeText: ["周", "月", "年", "时间段"],
      input2: "",
      modeltype: 2,
      incomeList: null,
      echartsObj: {
        id: "xxecharts",
        xAxisData: [],
        seriesData0: [],
        seriesData1: [],
        seriesName0: "订单量",
        seriesName1: "耗电量",
      },
      pageParam: [],
      canEditBtn: false,
    };
  },

  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },

  watch: {
    startTime: {
      handler(val) {
        if (val && !!this.detail.companyId && this.activeName == "tjxx") {
          this.getStatOrderEchartsData(this.detail.companyId);
          this.getAmountTotal();
        }
      },
    },
  },

  activated() {
    const id = parseInt(this.$route.query.id, 10);
    if (id && id !== this.id) {
      this.id = id;
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getIncomeDetailInfo,
          params: {
            incomeId: this.id,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            const that = this;
            const data = res.result.data;
            this.canEditBtn = data.companyId == this.company.id ? true : false;
            this.detail = res.result.data;
            if (res.result.data.contractFile) {
              const d = data.contractFile.split("/");
              this.detail.contractFileText = d[d.length - 1];
            }
            const num = this.detail.targetList
              .filter((item) => {
                return item.targetCompanyId == this.detail.companyId;
              })
              .map((oitem) => {
                return oitem.rate;
              })
              .reduce(function (prev, cur) {
                return parseInt(prev) + parseInt(cur);
              });
            this.detail.merchantIncome = num / 100;
            this.getStatOrderEchartsData(res.result.data.companyId);
            this.getAmountTotal(res.result.data.companyId);
            if (data.rangeList) {
              this.targetList = data.targetList.map((item) => {
                if (item.testRun != 1) {
                  data.rangeList.map((oitem) => {
                    if (item.rateRangeId == oitem.id) {
                      (item.maxAmount = oitem.maxAmount), (item.minAmount = oitem.minAmount);
                    }
                  });
                }
                return item;
              });
            } else {
              this.targetList = data.targetList;
            }
            const unique = function (arr, val) {
              const res = new Map();
              return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
            };
            const list = unique(that.targetList, "targetCompanyId");
            const result = list.map((item) => {
              const olist = that.targetList.filter((oitem) => {
                return oitem.targetCompanyId == item.targetCompanyId;
              });
              let c;
              olist.map((item, index) => {
                if (item.testRun) {
                  if (item.rate) {
                    c = item.rate;
                  }
                  olist.splice(index, 1);
                }
              });
              const obj = {
                targetCompanyId: item.targetCompanyId,
                targetCompanyName: item.targetCompanyName,
                rate: c || 0,
                list: olist,
              };
              return obj;
            });
            this.incomeList = result;
          }
        });
    },

    handleClick(tab) {
      if (tab.index == "0") {
        this.getStatOrderEchartsData(this.detail.companyId);
      }
    },

    //获取收益趋势图表的数据
    getStatOrderEchartsData(id) {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmoutList,
          params: {
            currentCompanyId: id,
            incomeId: this.detail.id,
            queryStartDate: this.startTime,
            queryEndDate: this.endTime,
            statIncome: 1,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.echartsObj.xAxisData = [];
            this.echartsObj.seriesData0 = [];
            this.echartsObj.seriesData1 = [];
            res.result.data.map((item) => {
              this.echartsObj.xAxisData.push(item.fullDateText);
              this.echartsObj.seriesData0.push(item.orderNum);
              this.echartsObj.seriesData1.push(item.orderAmount);
            });
          }
        });
    },

    getAmountTotal() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmountTotal,
          params: {
            incomeId: this.detail.id,
            queryStartDate: this.startTime,
            queryEndDate: this.endTime,
            statIncome: 1,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            const amount =
              res.result.data.thisTimeOrderAmount - res.result.data.lastTimeOrderAmount;
            const num = res.result.data.thisTimeOrderNum - res.result.data.lastTimeOrderNum;
            this.detailAmout = {
              ...res.result.data,
              difAmount:
                amount < 0 ? "- ¥" + util.numFormat(amount) : "+ ¥" + util.numFormat(amount),
              difNum: num < 0 ? "-" + num : "+" + num,
            };
          }
        });
    },
    handleChange() {
      this.$router.push({
        path: "/project/project-edit",
        query: {
          incomeId: this.detail.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectDetail {
  font-size: 14px;
  height: 100%;
  overflow-y: auto;

  > div {
    border-radius: 4px;
  }

  &-box {
    border-radius: 4px;
    background: #fff;
  }

  &-top {
    &-tabs {
      .tjxx {
        &-echarts {
          &-title {
            width: 100%;
            padding: 16px 25px;
            border-bottom: 1px solid #ebf0f5;
            font-size: 16px;
          }

          &-box {
            padding: 15px 24px 10px;
          }
        }
      }

      .fcbl {
        padding: 24px;
        background: #fff;
        border-radius: 4px;

        &-table {
          margin-top: 16px;
          width: 100%;
          border: 1px solid #dadde0;
          padding: 0 16px 12px;

          > div {
            width: 100%;

            &:nth-of-type(1) {
              border: none;
              padding: 24px 0;
            }

            &:nth-of-type(2) {
              background: #f5f7fa;
            }

            padding: 12px 0;
            border-bottom: 1px solid #fafbfc;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;

            > * {
              width: 50%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .zdxx {
    background: #fff;
    padding: 10px;

    &-table {
      margin-top: -24px;
      background: #fff;
      width: 100%;
    }
  }
}
.my-tab {
  height: 100%;
  display: flex;
  flex-direction: column;

  /deep/ .el-tabs__nav-wrap {
    background-color: #fff;
    padding: 0 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &::after {
      background-color: white !important;
    }
  }

  /deep/ .el-tabs__content {
    flex: 1;

    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>
