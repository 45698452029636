<template>
  <le-pagview
    v-if="pageParam"
    style="margin-top: 0px !important"
    :page-param="pageParam"
    @setData="setTableData"
  >
    <el-table :data="tableData" :highlight-current-row="true" max-height="510" class="zdxx-table">
      <el-table-column prop="modifyTime" label="修改时间" width="300"></el-table-column>
      <el-table-column prop="modifiedBy" label="修改人" width="300"></el-table-column>
      <el-table-column prop="beforeContent" label="修改前比例" width="600">
        <template slot-scope="{ row }">
          <base-ellipsis :length="20">{{ row.beforeContent }}</base-ellipsis>
        </template>
      </el-table-column>
      <el-table-column prop="afterContent" label="修改后比例" width="600">
        <template slot-scope="{ row }">
          <base-ellipsis :length="20">{{ row.afterContent }}</base-ellipsis>
        </template>
      </el-table-column>
    </el-table>
  </le-pagview>
</template>

<script>
import { mapState } from "vuex";

import BaseEllipsis from "@/components-new/BaseEllipsis/index.vue";
import { projectIncomeDistriApi } from "@/services/apis/project/income-distri";

export default {
  components: { BaseEllipsis },

  props: {
    incomeId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      pageParam: "",
      tableData: [],
    };
  },

  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },

  watch: {
    incomeId: {
      handler(val) {
        if (val) {
          this.pageParam = {
            getDataFn: projectIncomeDistriApi.incomeShareRateModifyRecord,
            params: {
              incomeId: this.incomeId,
            },
          };
        }
      },
      immediate: true,
    },
  },

  methods: {
    setTableData(data) {
      this.tableData = data;
    },
  },
};
</script>
