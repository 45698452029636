import request from "@/plugins/axios";

class ProjectIncomeDistriApi {
  incomeShareRateModifyRecordURL = "/api/company/income/getIncomeShareRateModifyRecord";
  incomeShareRateModifyRecordEffectKey = `get${this.incomeShareRateModifyRecordURL}`;
  incomeShareRateModifyRecord = async (params) => {
    return request({
      url: this.incomeShareRateModifyRecordURL,
      params,
    });
  };
}

export const projectIncomeDistriApi = new ProjectIncomeDistriApi();
