<template>
  <div class="a-bg-white a-w-100 a-h-100 projectDetail-top">
    <div class="projectDetail-top-tabs fcbl a-flex-cfsfs" style="max-width: 900px">
      <template v-if="rateType == 1">
        <div class="fcbl-top">
          <div class="a-fs-16 a-fw-700">分成比例</div>
        </div>
        <div
          v-for="(item, index) in detailFrom.detail"
          :key="item.id"
          class="fcbl-table a-flex-cfsfs"
          style="padding-bottom: 0; border: none"
        >
          <div class="a-flex-rfsfs">
            <div class="a-flex-rsbc">
              <span class="a-c-second">收益方{{ index + 1 }}</span>
              <span class="a-flex-1" style="margin-left: -50px">{{ item.targetCompanyName }}</span>
            </div>

            <div>
              <span class="a-c-second">分成比例</span>
              <span class="a-ml-16">{{ item.rate / 100 }}%</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import util from "../../utils/util.js";
export default {
  props: {
    incomeId: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  data() {
    return {
      util: util,
      detailFrom: {
        detail: [],
      },

      sourceDetail: [], //编辑时从接口返回的原始明细
      rateType: null,

      rangeList: [],
    };
  },
  watch: {
    incomeId: {
      handler(val) {
        if (val) {
          this.getIncomeDetail();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 获取项目详情
     */
    getIncomeDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
          params: {
            incomeId: this.incomeId,
          },
        })
        .then(({ data }) => {
          this.rateType = data.rateType;
          this.sourceDetail = data.targetList;
          if (data.rangeList) {
            this.rangeList = data.rangeList.map((item) => {
              return {
                _id: item.id,
                ...item,
              };
            });
          }

          if (this.rateType == 2) {
            this.setRangeRateDetail();
          } else {
            this.detailFrom.detail = data.targetList;
          }
        });
    },
    /**
     * 动态调整收益明细
     */
    setRangeRateDetail() {
      /**
       * 从接口返回了初始化数据
       */
      this.sourceDetail.map((item) => {
        const details = this.detailFrom.detail.filter((detail) => {
          return item.targetCompanyId == detail.targetCompanyId && item.serNo == detail.serNo;
        });

        /**
         * 是否为新构造的明细
         */
        const isCreate = details.length == 0;
        const detail = !isCreate
          ? details[0]
          : {
              targetCompanyId: item.targetCompanyId,
              rate: item.rate / 100,
              testRunRate: 0,
              targetCompanyName: item.targetCompanyName,
              serNo: item.serNo,
              rangeDetail: [],
            };

        /**
         * 如果为新构造的明细则添加到明细中
         */
        if (isCreate) {
          this.detailFrom.detail.push(detail);
        }
        /**
         * 原始条目为试运行条目
         */
        if (item.testRun == 1) {
          detail.testRunRate = item.rate / 100;
          this.isOpenTestRun = true;
          return;
        }

        /**
         * 原始数据为浮动收益类型,则先找到匹配的收益区间,然后获取收益区间的ID
         */
        const range = this.rangeList.filter((range) => {
          return range._id == item.rateRangeId;
        })[0];
        detail.rangeDetail.push({
          _id: range._id,
          rate: item.rate / 100,
          minAmount: range.minAmount,
          maxAmount: range.maxAmount,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectDetail-top {
  background: #fff;
  padding: 24px;
  position: relative;

  &-item {
    display: flex;
    flex-direction: row;
    width: 33.3%;
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;

    > div {
      margin-right: 16px;
    }
  }

  &-tabs {
    top: 132px;
    left: 0;

    .tjxx {
      &-echarts {
        &-title {
          width: 100%;
          padding: 16px 25px;
          border-bottom: 1px solid #ebf0f5;
          font-size: 16px;
        }

        &-box {
          padding: 15px 24px 10px;
        }
      }
    }

    .fcbl {
      padding: 24px;
      background: #fff;
      border-radius: 4px;

      &-table {
        margin-top: 16px;
        width: 100%;
        border: 1px solid #dadde0;
        padding: 0 16px 12px;

        > div {
          width: 100%;

          &:nth-of-type(1) {
            border: none;
            padding: 24px 0;
          }

          &:nth-of-type(2) {
            background: #f5f7fa;
          }

          padding: 12px 0;
          border-bottom: 1px solid #fafbfc;
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;

          > * {
            width: 50%;
            text-align: center;
          }
        }
      }
    }

    .htxx {
      background: #fff;
      border-radius: 4px;

      > div {
        width: 33.33%;
        margin-bottom: 16px;

        a {
          text-decoration: none;
          color: #007aff;
          margin-left: 8px;
        }
      }
    }
  }
}

/deep/ .el-tabs__item {
  // color: #007aff;
  font-size: 14px;
}

/deep/ .el-tabs__item.is-active {
  color: #007aff;
  font-weight: bold;
}

/deep/ .el-tabs__nav-wrap::after {
  background: none !important;
}

/deep/ .el-tabs__header {
  margin-left: 24px;
}

.zdxx {
  background: #fff;
  padding: 24px;

  &-table {
    margin-top: -24px;
    background: #fff;
    width: 100%;
  }
}
</style>
