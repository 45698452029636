<template>
  <div>
    <le-date-change-time
      :active-t.sync="activeT"
      :start-time.sync="startTime"
      :end-time.sync="endTime"
    ></le-date-change-time>
    <div class="tjxx-echarts a-mt-16 a-w-100 a-br-4 a-bg-white">
      <div class="tjxx-echarts-title a-fw-700">{{ tjxxTimeText[activeT] }}收益趋势</div>
      <div class="tjxx-echarts-box">
        <div class="a-flex-rfsfs a-w-100">
          <div class="a-flex-cfsfs">
            <span class="a-c-normal">总交易额(元)</span>
            <div class="a-flex-rfsc a-mt-10">
              <div class="a-fw-700">
                <span class="a-fs-16">&yen;</span>
                <span class="a-fs-20">{{ util.numFormat(detailAmout.thisTimeOrderAmount) }}</span>
              </div>
              <span class="a-fs-12 a-c-blue a-ml-5">
                较上{{ tjxxTimeText[activeT] }}{{ detailAmout.difAmount }}
              </span>
            </div>
          </div>
          <div class="a-flex-cfsfs a-ml-49">
            <span class="a-c-normal">总订单量</span>
            <div class="a-flex-rfsc a-mt-10">
              <div class="a-fw-700">
                <span class="a-fs-16">&yen;</span>
                <span class="a-fs-20">{{ detailAmout.thisTimeOrderNum }}</span>
              </div>
              <span class="a-fs-12 a-c-blue a-ml-5">
                较上{{ tjxxTimeText[activeT] }}{{ detailAmout.difNum }}
              </span>
            </div>
          </div>
        </div>
        <le-echarts-line :echarts-obj="echartsObj"></le-echarts-line>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import util from "../../../src/utils/util";
import leDateChangeTime from "../components/form/le-date-changeTime.vue";

export default {
  components: {
    "le-date-change-time": leDateChangeTime,
  },
  props: {
    incomeId: {
      type: Number,
    },
  },
  data() {
    return {
      util: util,
      activeT: 1,
      startTime: "",
      endTime: "",
      tjxxTimeText: ["周", "月", "年", "时间段"],
      detailAmout: {},
      echartsObj: {
        id: "xxecharts",
        xAxisData: [],
        seriesData0: [],
        seriesData1: [],
        seriesName0: "订单量",
        seriesName1: "交易额",
      },
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  watch: {
    incomeId: {
      handler(val) {
        if (val) {
          this.getStatOrderEchartsData();
          this.getAmountTotal();
        }
      },
      immediate: true,
    },
    startTime: {
      handler(val) {
        if (val) {
          this.getStatOrderEchartsData();
          this.getAmountTotal();
        }
      },
    },
    endTime: {
      handler(val) {
        if (val) {
          this.getStatOrderEchartsData();
          this.getAmountTotal();
        }
      },
    },
  },
  methods: {
    //获取收益趋势图表的数据
    getStatOrderEchartsData() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmoutList,
          params: {
            incomeId: this.incomeId,
            queryStartDate: this.startTime,
            queryEndDate: this.endTime,
            statIncome: 1,
          },
        })
        .then(({ data }) => {
          this.echartsObj.xAxisData = [];
          this.echartsObj.seriesData0 = [];
          this.echartsObj.seriesData1 = [];
          data.map((item) => {
            this.echartsObj.xAxisData.push(item.fullDateText);
            this.echartsObj.seriesData0.push(item.orderNum);
            this.echartsObj.seriesData1.push(Number((item.orderAmount / 100).toFixed(2)));
          });
        });
    },

    getAmountTotal() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getStatOrderAmountTotal,
          params: {
            incomeId: this.incomeId,
            queryStartDate: this.startTime,
            queryEndDate: this.endTime,
            statIncome: 1,
          },
        })
        .then(({ data }) => {
          const amount = data.thisTimeOrderAmount - data.lastTimeOrderAmount;
          const num = data.thisTimeOrderNum - data.lastTimeOrderNum;

          this.detailAmout = {
            ...data,
            difAmount: amount < 0 ? "- ¥" + util.numFormat(amount) : "+ ¥" + util.numFormat(amount),
            difNum: num < 0 ? "-" + num : "+" + num,
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tjxx {
  &-echarts {
    &-title {
      width: 100%;
      padding: 16px 25px;
      border-bottom: 1px solid #ebf0f5;
      font-size: 16px;
    }

    &-box {
      padding: 15px 24px 10px;
    }
  }
}
</style>
