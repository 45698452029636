<template>
  <el-descriptions size="medium">
    <template #title>
      <div class="a-flex-rfsc">
        <span>{{ detail.name }}</span>
        <el-tag class="a-ml-08">运行{{ detail.runDays }}天</el-tag>
      </div>
    </template>
    <el-descriptions-item label="所属商户">
      <le-jumpto-detail
        url="/franchisee/franchisee-detail"
        class="a-c-blue"
        :d-query="{ code: detail.companyId }"
      >
        <span>{{ detail.companyName }}</span>
      </le-jumpto-detail>
    </el-descriptions-item>
    <el-descriptions-item label="所属商户收益">
      {{
        detail.maxRate == detail.minRate
          ? detail.maxRate / 100
          : detail.minRate / 100 + " ~ " + detail.maxRate / 100
      }}%
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import { mapState } from "vuex";

import util from "../../utils/util.js";

export default {
  props: {
    incomeId: {
      type: Number,
    },
  },

  data() {
    return {
      util: util,
      detail: {},
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  watch: {
    incomeId: {
      handler(val) {
        if (val) {
          this.getIncomeDetail();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 获取项目详情
     */
    getIncomeDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
          params: {
            incomeId: this.incomeId,
          },
        })
        .then(({ data }) => {
          this.detail = data;
        });
    },
  },
};
</script>
